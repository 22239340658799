export type Env = {
  APP_VERSION: string;
  APP_API_BASE_URL: string;
  APP_API_ACCESS_BRIDGE_URL: string;
  APP_PLAYER_ID: string;
  APP_FOOTER_TEXT: string;
  APP_DEFAULT_LANGUAGE: string;

  APP_DEFAULT_CONFIG_SOURCE?: string;
  APP_PLAYER_LICENSE_KEY?: string;

  APP_BODY_FONT?: string;
  APP_BODY_ALT_FONT?: string;

  // Following are OAuth related
  APP_OAUTH_ENABLED?: boolean;
  APP_OAUTH_CLIENT_ID?: string;
  APP_OAUTH_CLIENT_SECRET?: string;
  APP_OAUTH_STORAGE?: 'session' | 'local';
  APP_OAUTH_REDIRECT_URL?: string;
  APP_OAUTH_AUTO_LOGIN?: boolean;
  APP_OAUTH_AUTH_URL?: string;
  APP_OAUTH_TOKEN_URL?: string;
  APP_OAUTH_RESOURCE_URL?: string;
  APP_OAUTH_CONTENT_TOKEN_URL?: string;
  APP_OAUTH_SIGN_UP_URL?: string;
  APP_OAUTH_DASHBOARD_URL?: string;
  APP_OAUTH_UNLOCK_ONLY_PREMIUM?: boolean;
};

const env: Env = {
  APP_VERSION: '',
  APP_API_BASE_URL: 'https://cdn.jwplayer.com',
  APP_API_ACCESS_BRIDGE_URL: '',
  APP_PLAYER_ID: 'M4qoGvUk',
  APP_FOOTER_TEXT: '',
  APP_DEFAULT_LANGUAGE: 'en',
};

export const configureEnv = (options: Partial<Env>) => {
  env.APP_VERSION = options.APP_VERSION || env.APP_VERSION;
  env.APP_API_BASE_URL = options.APP_API_BASE_URL || env.APP_API_BASE_URL;
  env.APP_API_ACCESS_BRIDGE_URL = options.APP_API_ACCESS_BRIDGE_URL || env.APP_API_ACCESS_BRIDGE_URL;
  env.APP_PLAYER_ID = options.APP_PLAYER_ID || env.APP_PLAYER_ID;
  env.APP_FOOTER_TEXT = options.APP_FOOTER_TEXT || env.APP_FOOTER_TEXT;
  env.APP_DEFAULT_LANGUAGE = options.APP_DEFAULT_LANGUAGE || env.APP_DEFAULT_LANGUAGE;

  env.APP_DEFAULT_CONFIG_SOURCE ||= options.APP_DEFAULT_CONFIG_SOURCE;
  env.APP_PLAYER_LICENSE_KEY ||= options.APP_PLAYER_LICENSE_KEY;

  env.APP_BODY_FONT = options.APP_BODY_FONT || env.APP_BODY_FONT;
  env.APP_BODY_ALT_FONT = options.APP_BODY_ALT_FONT || env.APP_BODY_ALT_FONT;

  env.APP_OAUTH_ENABLED ||= options.APP_OAUTH_ENABLED;
  env.APP_OAUTH_CLIENT_ID ||= options.APP_OAUTH_CLIENT_ID;
  env.APP_OAUTH_CLIENT_SECRET ||= options.APP_OAUTH_CLIENT_SECRET;
  env.APP_OAUTH_STORAGE ||= options.APP_OAUTH_STORAGE;
  env.APP_OAUTH_REDIRECT_URL ||= options.APP_OAUTH_REDIRECT_URL;
  env.APP_OAUTH_AUTO_LOGIN ||= options.APP_OAUTH_AUTO_LOGIN;
  env.APP_OAUTH_AUTH_URL ||= options.APP_OAUTH_AUTH_URL;
  env.APP_OAUTH_TOKEN_URL ||= options.APP_OAUTH_TOKEN_URL;
  env.APP_OAUTH_RESOURCE_URL ||= options.APP_OAUTH_RESOURCE_URL;
  env.APP_OAUTH_CONTENT_TOKEN_URL ||= options.APP_OAUTH_CONTENT_TOKEN_URL;
  env.APP_OAUTH_SIGN_UP_URL ||= options.APP_OAUTH_SIGN_UP_URL;
  env.APP_OAUTH_DASHBOARD_URL ||= options.APP_OAUTH_DASHBOARD_URL;
  env.APP_OAUTH_UNLOCK_ONLY_PREMIUM ||= options.APP_OAUTH_UNLOCK_ONLY_PREMIUM;
};

export default env;
